<template>
  <div class="row">
    <compania-slas-dano :id="id" :nuevo="true" :tiposdano="tiposdano" :tratamientos="tratamientos" :datos="datos" />
    <!--<div class="col-md-2">
            <button class="btn btn-primary">{{ $t('general.crear') }}</button>
        </div>-->
    <compania-slas-dano :nuevo="false" v-for="sla in slas" :key="sla" :sla="sla" :id="id" :tiposdano="tiposdano" :tratamientos="tratamientos" :datos="datos" />

  </div>
</template>
<script>
import { PwgsApi } from '../../../services/PwgsApi';
import CompaniaSlasDano from './CompaniaSlasDano.vue'
export default {
  components: {
    'compania-slas-dano': CompaniaSlasDano
  },
  props: ['id', 'datos'],
  data() {
    return {
      slas: '',
      tiposdano: '',
      tratamientos:'',
      
    }
  },

/*
https://api.pwgs.apuyen/modulos/pwgsapi/index.php/tratamientos-Caser if datos.sistema_compannia == "P.A.P."
https://api.pwgs.apuyen/modulos/pwgsapi/index.php/tipos-dano
*/
methods: {
  async obtenerSLAs() {
      this.slas = '';
      const api = new PwgsApi();
      this.slas = await api.get('companias/' + this.$props.id + '/slas');
       
      console.log("slas", this.slas);
      console.log("sistemacompania", this.$props.datos.sistema_compannia);

    },
    async obtenerTiposDano() {
      const api = new PwgsApi();
      let aux = await api.get('tipos-dano/todos');
      this.tiposdano = aux.datos;
      console.log("tiposdano", this.tiposdano);
  },
  async obtenerTratamientos() {
    this.tratamientos = '';
    if (this.$props.datos.sistema_compannia == "P.A.P."){
      const api = new PwgsApi();
      this.tratamientos = await api.get('tratamientos-Caser');
       
    }
  },
  
  },
  watch: {
    id() {
      this.obtenerSLAs();
    },


  },
  mounted() {
    this.obtenerSLAs();
    this.obtenerTiposDano();
    this.obtenerTratamientos();
  },

}

</script>
